<template>
	<div>
	<ContentHeader title="Laporan Pergantian Shift" />
     <div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
	            <div class="col">
	            	<div class="card">
	            		<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Laporan Pergantian Shift</h3>
	            		</div>
	            		<div class="card-body">
	            			<div class="row align-items-center">
	            				<div class="col-lg-2">
	            					<span>Jumlah Baris</span>
	            					<select class="custom-select form-control" v-model="page" @change="getDataResult">
		            					<option value="10">10</option>
		            					<option value="15">15</option>
		            					<option value="20">20</option>
		            					<option value="25">25</option>
		            					<option value="30">30</option>
		            					<option value="40">40</option>
		            					<option value="45">45</option>
		            					<option value="50">50</option>
		            				</select>
	            				</div>
	            				<div class="col-lg-2">
	            				</div>
	            				<div class="col-lg-8"></div>
	            			</div>
	            			<table class="table table-bordered table-hover mt-4">
							  <thead>
							    <tr>
							      <th scope="col" class="text-sm">NO.</th>
							      <th scope="col" class="text-sm">SHIFT</th>
							      <th scope="col" class="text-sm">BUKA SHIFT</th>
							      <th scope="col" class="text-sm">TUTUP SHIFT</th>
							      <th scope="col" class="text-sm">SALDO AWAL</th>
							      <th scope="col" class="text-sm">SALDO AKHIR</th>
							      <th scope="col" class="text-sm">SALDO KASIR</th>
							      <th scope="col" class="text-sm">SELISIH SALDO</th>
							      <th scope="col" class="text-sm">DISERAHKAN</th>
							      <th scope="col" class="text-sm">CATATAN</th>
							    </tr>
							  </thead>
							  <tbody>
							    <tr v-for="(row, index) in data_result" :key="row.id">
							      <td class="text-sm" scope="row">{{ index + 1 }}</td>
							      <td class="text-sm" scope="row">
                                      <p>{{ row.jadwal_shift.nama ?? row.jadwal_shift.nama }}</p>
                                      <span>{{ dayjs(row.jadwal_shift.mulai).format('HH:mm:ss') }} - {{ dayjs(row.jadwal_shift.selesai).format('HH:mm:ss') }}</span>
                                  </td>
							      <td class="text-sm" scope="row">{{ dayjs(row.created_at).format('DD/MM/YYYY') }}</td>
							      <td class="text-sm" scope="row">{{ dayjs(row.updated_at).format('DD/MM/YYYY') }}</td>
							      <td class="text-sm" scope="row">{{ format_nominal(row.saldo_awal) }}</td>
							      <td class="text-sm" scope="row">{{ format_nominal(row.saldo_akhir) }}</td>
							      <td class="text-sm" scope="row">{{ format_nominal(row.saldo_kasir) }}</td>
							      <td class="text-sm" scope="row">{{ format_nominal(row.selisih_saldo) }}</td>
							      <td class="text-sm" scope="row">{{ row.karyawan_id ? row.karyawan.nama_lengkap : '-' }}</td>
							      <td class="text-sm" scope="row">{{ row.catatan }}</td>
							    </tr>
							  </tbody>
							</table>
							<p class="text-center mt-4" v-if="memuat_data">Memuat Data</p>
							<p class="text-center mt-4" v-if="cek_data">Data Masih Kosong</p>
	            		</div>
	            		<div class="card-footer">
							<nav aria-label="...">
							  <ul class="pagination">
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
							      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
							    </li>
							    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
							      <a class="page-link" href="#">Next</a>
							    </li>
							  </ul>
							</nav>
	            		</div>
	            	</div>
	            </div>
	        </div>
		</div>
	</div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'

import axios from 'axios'
import { ref, onMounted, computed } from 'vue' 
// import router from '@/router'
import * as dayjs from 'dayjs'
import store from '@/store'
import format_nominal from '@/format_nominal'

const cabang = computed(() => store.getters['auth/cabang'])
const data_result = ref([])
const cek_data = ref(false)
const memuat_data = ref(true)
const page = ref(10)
const links = ref([])
const pagination = ref([])

const getDataResult = async () => {
	data_result.value = []
	memuat_data.value = true

	let { data } = await axios.get(`api/shift/getAll/${cabang.value.id}/${page.value}`)

	if (data != 'kosong') {
		memuat_data.value = false
		cek_data.value = false
		data_result.value = data.data
		links.value = data.links
		links.value.splice(0, 1)
		links.value.splice(links.value.length-1, 1)
		pagination.value = data
	} else {
		cek_data.value = true
		memuat_data.value = false
		data_result.value = []
	}
}


const paginateData = async (url) => {

	if (url != null) {
		data_result.value = []
		memuat_data.value = true

		let link_url = new URL(url)
		link_url = `${link_url.pathname}${link_url.search}`
		
		let { data } = await axios.get(`${link_url}`)

		
		if (data != 'kosong') {
			memuat_data.value = false
			data_result.value = data.data	
			links.value = data.links
			links.value.splice(0, 1)
			links.value.splice(links.value.length-1, 1)
			pagination.value = data
		}
	}
}

onMounted(() => {
	getDataResult()
})
</script>

<style>
	
</style>